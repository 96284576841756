<template>
  <div class="col-12 g c col-lg-4">
    <br><br>
    <div class="card card-body">
        <img :src="require('@/assets/images/banner.png')" style="width: 50%; margin: 0px auto; padding: 20px" alt="">
        <br>
        <div class="col-12 text-center text-danger">
            للمدارس فقط وليست لأولياء الامور ولا الطلاب.
        </div>
        <div class="alert alert-danger text-center g" v-if="error">
            {{ error }}
        </div>
        <div class="form-group g">
          <h5 for="">الاسم</h5>
          <input type="text"
            class="form-control form-control-lg" v-model="name" v-on:keyup.enter="onEnter" placeholder="اكتب اسم المدرسة هنا...">
        </div>
        <div class="form-group g">
          <h5 for="">الجوال</h5>
          <input type="text"
            class="form-control form-control-lg" v-model="phone" v-on:keyup.enter="onEnter" placeholder="اكتب رقم الجوال هنا... (يكون عليه واتساب)">
        </div>
        <div class="form-group g">
          <h5 for="">كلمة المرور</h5>
          <input type="password"
            class="form-control form-control-lg" v-model="password" v-on:keyup.enter="onEnter" placeholder="اكتب كلمة مرور للدخول بها لحسابك...">
        </div>
        <div class="form-group g">
          <h5 for="">المنطقة</h5>
          <select class="form-control form-control-lg" v-model="location">
            <option value="">-- اختر المنطقة --</option>
            <option :value="location" v-for="location in locations" :key="location">
                {{ location }}
            </option>
          </select>
        </div>
        <div class="gd"></div>
            <b-form-checkbox style="margin-right: 10px"
                v-model="checked"
                :value="true"
            >
                أقر بأنني مدرسة ولست ولي أمر او طالب.
            </b-form-checkbox>
            <br>
        <div class="col-12 text-center g">
            <button class="btn btn-success" v-if="checked" @click="login()">
                <i class="fa fa-check"></i>
                 انشاء الحساب الآن
            </button>
            <button class="btn btn-success disabled" disabled v-if="!checked">
                <i class="fa fa-check"></i>
                 انشاء الحساب الآن
            </button>
        </div>
        <br>
        <span>
             لديك حساب؟
            <a href="javascript:;" @click="$router.push('/login')">
                <i class="fa fa-sign-in"></i>
                تسجيل الدخول
            </a>
        </span>
    </div>
    <div class="col-12 text-center g">
        احدى مشاريع شركة <a href="https://brmja.com" target="_blank">برمجة دوت كوم</a>
    </div>
  </div>
</template>

<script>

import { VBModal, BModal, BFormCheckbox, BFormRadio, BFormGroup } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox,
        BFormRadio,
        BFormGroup,
        BModal
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    created(){
        var g = this;
        $.post(api + '/user/auth/locations').then(function(e){
            g.locations = e.response
        })
    },
    data(){
        return {
            checked: false,
            phone: "",
            password: "",
            name: "",
            error: null,
            location: "",
            locations: [],
            code: window.location.hash ? window.location.hash.replace("#", "") : null
        }
    },
    methods: {
        onEnter: function() {
            this.login()
        },
        login(){
            var g = this;
            g.error = null;
            if(!g.location || g.location == ""){
                return alert("برجاء اختيار المنقطة", 200)
            }
            $.post(api + '/user/auth/register', {
                phone: g.phone,
                password: g.password,
                name: g.name,
                code: g.code,
                location: g.location
            }).then(function(r){
                if(r.status != 100){
                    g.error = r.response
                }else{
                    localStorage.setItem("user", JSON.stringify(r.response))
                    window.location = '/'
                }
            }).fail(function(){
                g.error = "حدث مشكلة في الاتصال"
            })
        }
    }
}
</script>

<style>

</style>